import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import RightLineArrowIcon from "../Icons/RightLineArrowIcon"
import { FormControlLabel } from "../Section"
import FormButton from "../Button/FormButton"

class PersonalInformationRto extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      source: "",
      page: "",
      form: "",
      name: "",
      email: "",
      phone_no: "",
      zipcode: "",
      state: "",
      comment: "",
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search)
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"))
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"))
        sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"))
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"))
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"))
      }
    }
  }
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
    if (id === "comment") {
      if (!value || value.trim() === "") {
        event.target.setCustomValidity("Please fill out this field.")
      } else {
        event.target.setCustomValidity("")
      }
    }
  }
  handleSubmit(event) {
    let utm_source_val = "website"
    let utm_medium_val = ""
    let utm_campaign_val = ""
    let utm_term_val = ""
    let utm_content_val = ""
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source")
      utm_medium_val = sessionStorage.getItem("utm_medium")
      utm_campaign_val = sessionStorage.getItem("utm_campaign")
      utm_term_val = sessionStorage.getItem("utm_term")
      utm_content_val = sessionStorage.getItem("utm_content")
    }
    event.preventDefault()
    const { location } = this.props
    const data = {
      source: location.origin,
      page: location.href,
      form: event.target.id,
      first_name: this.state.name,
      email: this.state.email,
      phone_no: this.state.phone_no,
      zipcode: this.state.zipcode,
      state: this.state.state,
      comment: this.state.comment,
      utm_medium: utm_medium_val,
      utm_term: utm_term_val,
      utm_campaign: utm_campaign_val,
      utm_content: utm_content_val,
      lead_source: utm_source_val,
      api_token: process.env.GATSBY_FORM_CRM_TOKEN,
    }
    if (typeof window !== `undefined`) {
      document
        .getElementById("personalInformationRtoBtn")
        .setAttribute("disabled", "")
    }
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        if (typeof dataLayer !== "undefined") {
          window.dataLayer.push({
            event: "form_submission",
            formName: "PersonalInformationRtoForm",
            enhanced_conversion_data: {
              email: this.state.email,
              phone_number: this.state.phone_no,
            },
          })
        }
        this.setState({
          source: "",
          page: "",
          form: "",
          name: "",
          email: "",
          phone_no: "",
          zipcode: "",
          state: "",
          comment: "",
        })
        navigate("/thank-you-rent-to-own")
      })
      .catch(error => {
        alert(error)
      })
  }
  render() {
    const { states } = this.props
    return (
      <form id="personalFormRTO" onSubmit={this.handleSubmit} className="grid">
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="text"
              id="name"
              className="form-control"
              onChange={this.handleChange}
              pattern="^(?!\s*$)[A-Za-z\s]+$"
              required
            />
            <FormControlLabel
              className={
                this.state.name !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              Name
            </FormControlLabel>
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="email"
              id="email"
              className="form-control"
              onChange={this.handleChange}
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
              required
            />
            <FormControlLabel
              className={
                this.state.email !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              Email Address
            </FormControlLabel>
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <input
              type="tel"
              id="phone_no"
              className="form-control"
              onChange={this.handleChange}
              pattern="[0-9]{10}"
              required
            />
            <FormControlLabel
              className={
                this.state.phone_no !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              Phone Number
            </FormControlLabel>
          </div>
        </div>
        <div className="grid-md-6">
          <div className="form-group-bordered">
            <input
              type="text"
              id="zipcode"
              className="form-control"
              onChange={this.handleChange}
              pattern="^[0-9]{5}$"
              required
            />
            <FormControlLabel
              className={
                this.state.zipcode !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              Zip Code
            </FormControlLabel>
          </div>
        </div>
        <div className="grid-md-6">
          <div className="form-group-bordered">
            <select
              id="state"
              className="form-control"
              onChange={this.handleChange}
              aria-label="state"
              required
            >
              <option value="">Select State</option>
              {states.map(item => (
                <option key={item.node.name} value={item.node.name}>
                  {item.node.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="grid-12">
          <div className="form-group-bordered">
            <textarea
              id="comment"
              className="form-control"
              onChange={this.handleChange}
              required
            />
            <FormControlLabel
              className={
                this.state.comment !== ""
                  ? "control-label active"
                  : "control-label"
              }
            >
              Type your message here…
            </FormControlLabel>
          </div>
        </div>
        <div className="grid-12">
          <div className="form-action">
            <FormButton
              id="personalInformationRtoBtn"
              icon={<RightLineArrowIcon />}
              text="Submit"
            />
          </div>
        </div>
      </form>
    )
  }
}

export default PersonalInformationRto
