import React from "react"
import styled from "styled-components"
import { CustomValidationMessage, RequiredTriangle } from "../Section"

const CustomRequired = styled(CustomValidationMessage)`
  top: 50px;
  left: -20px;
  z-index: 10;
  color: red;
`

const ThirdStep = props => {
  if (props.currentStep !== 3) return null

  return (
    <div className="grid">
      <div className="grid-12">
        <div className="form-group-bordered">
          <input
            type="text"
            id="monthly_payment"
            className="form-control"
            onChange={props.handleChange}
            placeholder="Mortgage Payment per Month*"
            pattern="^(?!\s*$)[A-Za-z\s]+$"
            required
          />
        </div>
      </div>
      <div className="grid-12">
        <div className="form-group-bordered">
          <input
            type="text"
            id="employer_name"
            className="form-control"
            onChange={props.handleChange}
            placeholder="Employer Name (if self or retired state so)*"
            pattern="^(?!\s*$)[A-Za-z\s]+$"
            required
          />
        </div>
      </div>
      <div className="grid-12">
        <div className="form-group-bordered">
          <input
            type="text"
            id="occupation"
            className="form-control"
            onChange={props.handleChange}
            placeholder="Occupation*"
            pattern="^(?!\s*$)[A-Za-z\s]+$"
            required
          />
        </div>
      </div>
      <div className="grid-12">
        <div className="form-group-bordered">
          <input
            type="tel"
            id="employer_phone_no"
            className="form-control"
            onChange={props.handleChange}
            pattern="[0-9]{10}"
            placeholder="Employer Phone Number(xxx-xxx-xxxx)*"
            required
          />
        </div>
      </div>
      <div className="grid-12">
        <div className="form-group-bordered">
          <input
            type="text"
            id="ext"
            className="form-control"
            onChange={props.handleChange}
            placeholder="Ext"
            pattern="[0-9]"
            required
          />
        </div>
      </div>
      <div className="grid-12">
        <div className="form-group-bordered">
          <input
            type="text"
            id="time_employed"
            className="form-control"
            onChange={props.handleChange}
            placeholder="Time Employed*"
            pattern="^(?!\s*$)[A-Za-z\s]+$"
            required
          />
        </div>
      </div>
      <div className="grid-12">
        <div className="form-group-bordered">
          <input
            type="text"
            id="monthly_income"
            className="form-control"
            onChange={props.handleChange}
            placeholder="Monthly Employment Income*"
            pattern="^(?!\s*$)[A-Za-z\s]+$"
            required
          />
        </div>
      </div>
      <div className="grid-12">
        <div className="form-group-bordered">
          <input
            type="text"
            id="other_monthly_income"
            className="form-control"
            onChange={props.handleChange}
            placeholder="Other Montlhy Income*"
            pattern="^(?!\s*$)[A-Za-z\s]+$"
            required
          />
        </div>
      </div>
      <div className="grid-12">
        <div className="form-group-bordered">
          <select
            id="co_application"
            className="form-control"
            onChange={props.handleChange}
          >
            <option value="">
              Is there a Co-Applicant?(must be in order to qualify)
            </option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
      </div>
    </div>
  )
}

export default ThirdStep
