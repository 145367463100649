import React from "react"
import axios from "axios"
import styled from "styled-components"
import { navigate } from "gatsby"

import FirstStep from "./FirstStep"
import SecondStep from "./SecondStep"
import ThirdStep from "./ThirdStep"

import BreakpointUp from "../Media/BreakpointUp"
import BreakpointDown from "../Media/BreakpointDown"
import { FormBtn } from "../Button/FormButton"
import RightLineArrowIcon from "../Icons/RightLineArrowIcon"

const FormStep = styled.div`
  margin: 0;
`
const FormStepHeading = styled.div`
  background-color: rgba(223, 227, 239, 0.4);
  padding: 0;
  text-align: center;
  color: #000;
  font-size: 24px;
  font-weight: 900;
  position: relative;
  &:after,
  &:before {
    content: "";
    left: 0;
    right: 0;
    position: absolute;
    width: 890px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #dfe3ef;
    height: 4px;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  .title {
    font-size: 20px;
    line-height: 30px;
    top: -15px;
    position: relative;
    margin-bottom: 0;
    ${BreakpointUp.lg` 
            font-size: 24px;
            line-height: 34px;
        `}
  }
`
const FormStepBody = styled.div`
  background-color:#fff;
  max-width:545px;
  width:100%;
  margin:0 auto;
  padding:30px;
  ${BreakpointUp.md`  
    padding:40px;
 `}
  ${BreakpointUp.lg`  
    padding:60px;
  `}
  .tab-content {
    padding: 0 15px;
    select.form-control {
      padding: 0 15px 15px 0;
    }
  }
`
const FormStepFooter = styled.div`
  padding: 30px 0;
  background-color: rgba(223, 227, 239, 0.4);
  color: #000;
  position: relative;
  &:after,
  &:before {
    content: "";
    left: 0;
    right: 0;
    position: absolute;
    width: 890px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #dfe3ef;
    height: 4px;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
`
const FormButtonToolbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 890px;
  width: 100%;
  strong {
    font-weight: 700;
    font-size: 16px;
    ${BreakpointUp.sm`
            font-weight:900;
            font-size:24px;            
        `}
  }
  .btn {
    ${BreakpointDown.sm`
            padding: 10px 20px;
            font-size: 16px;   
        `}
  }
`
const NavStep = styled.ul`
  list-style: none;
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const NavStepItem = styled.li`
  margin: 0;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  padding: 0 5px;
  ${BreakpointUp.sm`  
      padding:0 15px;
    `}
  ${BreakpointUp.md`  
      padding:0 30px;
    `} 
    &:after {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background-color: transparent;
    position: absolute;
    display: block;
    z-index: 1;
  }
  &.active {
    &:after {
      background-color: #fc0002;
    }
    & .circle {
      border-color: #fc0002;
    }
    & .text {
      color: #000;
    }
  }
`
const NavStepLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  padding: 20px 0;
  font-weight: 700;
  flex-direction: column;
  font-size: 12px;
  line-height: 18px;
  ${BreakpointUp.sm`  
      font-size:14px;   
      line-height:20px;      
    `}
  ${BreakpointUp.md`  
      flex-direction:row;    
      padding:35px 0;        
    `}
    &:hover, &:focus {
    color: #000;
  }
`
const NavStepCircle = styled.div`
  font-size: 18px;
  color: #fc0002;
  font-weight: 900;
  text-align: center;
  width: 40px;
  height: 40px;
  border: 6px solid #dfe3ef;
  border-radius: 50%;
  background-color: #fff;
  line-height: 30px;
  margin-bottom: 5px;
  ${BreakpointUp.md`
  margin-bottom: 0px;
  `}
  + .text {
    ${BreakpointUp.md`
      margin-left:15px;
    `}
  }
`
const NavStepText = styled.div`
  margin: 0;
`

class ApplicationInfoForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 1, // Default is Step 1
      source: "",
      page: "",
      form: "",
      salesperson_dealer: "",
      home_owner: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      birthday: "",
      sDealerRequire: false,
      homeOwnerRequire: false,
      fNameRequire: false,
      lNameRequire: false,
      emailRequire: false,
      phoneNoRequire: false,
      birthdayRequire: false,
      street_address: "",
      street_address_2: "",
      city: "",
      state: "",
      zipcode: "",
      streetAddressRequire: false,
      cityRequire: false,
      stateRequire: false,
      zipRequire: false,
      monthly_payment: "",
      employer_name: "",
      occupation: "",
      employer_phone_no: "",
      ext: "",
      time_employed: "",
      monthly_income: "",
      other_monthly_income: "",
      co_application: "",
      mPyamentRequire: false,
      eNameRequire: false,
      occupationRequire: false,
      ePhoneRequire: false,
      timeEmployedRequire: false,
      mIncomeRequire: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this._next = this._next.bind(this)
  }
  componentDidMount() {
    if (typeof window !== "undefined") {
      const searchParams = new URLSearchParams(window.location.search)
      if (
        searchParams.get("utm_source") != "" &&
        searchParams.get("utm_source") != null
      ) {
        sessionStorage.setItem("utm_source", searchParams.get("utm_source"))
        sessionStorage.setItem("utm_medium", searchParams.get("utm_medium"))
        sessionStorage.setItem("utm_campaign", searchParams.get("utm_campaign"))
        sessionStorage.setItem("utm_term", searchParams.get("utm_term"))
        sessionStorage.setItem("utm_content", searchParams.get("utm_content"))
      }
    }
  }
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
    if (id === "salesperson_dealer") {
      this.setState({
        sDealerRequire: false,
      })
    }
    if (id === "home_owner") {
      this.setState({
        homeOwnerRequire: false,
      })
    }
    if (id === "first_name") {
      this.setState({
        fNameRequire: false,
      })
    }
    if (id === "last_name") {
      this.setState({
        lNameRequire: false,
      })
    }
    if (id === "email") {
      this.setState({
        emailRequire: false,
      })
    }
    if (id === "phone_no") {
      this.setState({
        phoneNoRequire: false,
      })
    }
    if (id === "birthday") {
      this.setState({
        birthdayRequire: false,
      })
    }
    if (id === "street_address") {
      this.setState({
        streetAddressRequire: false,
      })
    }
    if (id === "city") {
      this.setState({
        cityRequire: false,
      })
    }
    if (id === "state") {
      this.setState({
        stateRequire: false,
      })
    }
    if (id === "zipcode") {
      this.setState({
        zipRequire: false,
      })
    }
    if (id === "monthly_payment") {
      this.setState({
        mPyamentRequire: false,
      })
    }
    if (id === "employer_name") {
      this.setState({
        eNameRequire: false,
      })
    }
    if (id === "occupation") {
      this.setState({
        occupationRequire: false,
      })
    }
    if (id === "employer_phone_no") {
      this.setState({
        ePhoneRequire: false,
      })
    }
    if (id === "time_employed") {
      this.setState({
        timeEmployedRequire: false,
      })
    }
    if (id === "monthly_income") {
      this.setState({
        mIncomeRequire: false,
      })
    }
  }
  handleSubmit(event) {
    let utm_source_val = "website"
    let utm_medium_val = ""
    let utm_campaign_val = ""
    let utm_term_val = ""
    let utm_content_val = ""
    if (
      sessionStorage.getItem("utm_source") != "" &&
      sessionStorage.getItem("utm_source") != null
    ) {
      utm_source_val = sessionStorage.getItem("utm_source")
      utm_medium_val = sessionStorage.getItem("utm_medium")
      utm_campaign_val = sessionStorage.getItem("utm_campaign")
      utm_term_val = sessionStorage.getItem("utm_term")
      utm_content_val = sessionStorage.getItem("utm_content")
    }
    event.preventDefault()
    const { location } = this.props

    const comment =
      "Salesperson Dealer: " +
      this.state.salesperson_dealer +
      "; Home Owner: " +
      this.state.home_owner +
      "; Date of Birth: " +
      this.state.birthday +
      "; Monthly Payment: " +
      this.state.monthly_payment +
      "; Employer Name: " +
      this.state.employer_name +
      "; Employer Phone: " +
      this.state.employer_phone_no +
      "; Occupation: " +
      this.state.occupation +
      "; Time Employed:" +
      this.state.time_employed +
      "; Ext:" +
      this.state.ext +
      "; Monthly Income:" +
      this.state.monthly_income +
      "; Other Monthly Income:" +
      this.state.other_monthly_income +
      "; Is there a Co-Applicant? :" +
      this.state.co_application

    const data = {
      source: location.origin,
      page: location.href,
      form: "Application Info Form",
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      phone_no: this.state.phone_no,
      address_line_1: this.state.street_address,
      city: this.state.city,
      state: this.state.state,
      zipcode: this.state.zipcode,
      comment: comment,
      utm_medium: utm_medium_val,
      utm_term: utm_term_val,
      utm_campaign: utm_campaign_val,
      utm_content: utm_content_val,
      lead_source: utm_source_val,
      api_token: process.env.GATSBY_FORM_CRM_TOKEN,
    }
    if (typeof window !== `undefined`) {
      document.getElementById("applyInfoFormBtn").setAttribute("disabled", "")
    }
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: {
        Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN,
      },
    })
      .then(res => {
        this.setState({
          source: "",
          page: "",
          form: "",
          salesperson_dealer: "",
          home_owner: "",
          first_name: "",
          last_name: "",
          email: "",
          phone_no: "",
          birthday: "",
          street_address: "",
          street_address_2: "",
          city: "",
          state: "",
          zipcode: "",
          monthly_payment: "",
          employer_name: "",
          occupation: "",
          employer_phone_no: "",
          ext: "",
          time_employed: "",
          monthly_income: "",
          other_monthly_income: "",
          co_application: "",
        })
        navigate("/thank-you-apply-for-financing")
      })
      .catch(error => {
        alert(error)
      })
  }
  _next() {
    const formEl = document.getElementById("ApplicationInfoForm")
    if (formEl.checkValidity()) {
      this.setState({ currentStep: this.state.currentStep + 1 })
    } else {
      formEl.reportValidity()
    }
  }

  render() {
    const { currentStep } = this.state
    return (
      <form id="ApplicationInfoForm" onSubmit={this.handleSubmit}>
        <FormStep>
          <FormStepHeading>
            <div className="container">
              <h2 className="title">- Fill Applicant Information -</h2>
              <NavStep>
                <NavStepItem className={currentStep === 1 ? "active" : ""}>
                  <NavStepLink>
                    <NavStepCircle className="circle">1</NavStepCircle>
                    <NavStepText className="text">Personal Info</NavStepText>
                  </NavStepLink>
                </NavStepItem>
                <NavStepItem className={currentStep === 2 ? "active" : ""}>
                  <NavStepLink>
                    <NavStepCircle className="circle">2</NavStepCircle>
                    <NavStepText className="text">Full Address </NavStepText>
                  </NavStepLink>
                </NavStepItem>
                <NavStepItem className={currentStep === 3 ? "active" : ""}>
                  <NavStepLink>
                    <NavStepCircle className="circle">3</NavStepCircle>
                    <NavStepText className="text">Employee Details</NavStepText>
                  </NavStepLink>
                </NavStepItem>
              </NavStep>
            </div>
          </FormStepHeading>
          <FormStepBody>
            <div className="container">
              <div className="tab-content">
                <div className="tab-pane">
                  <FirstStep
                    currentStep={currentStep}
                    handleChange={this.handleChange}
                  />
                  <SecondStep
                    currentStep={currentStep}
                    handleChange={this.handleChange}
                  />
                  <ThirdStep
                    currentStep={currentStep}
                    handleChange={this.handleChange}
                  />
                </div>
              </div>
            </div>
          </FormStepBody>
          <FormStepFooter>
            <div className="container">
              <FormButtonToolbar>
                {currentStep === 3 ? (
                  <FormBtn
                    onClick={this.handleSubmit}
                    id="applyInfoFormBtn"
                    className="btn"
                    type="submit"
                  >
                    <span className="text">Submit</span>
                  </FormBtn>
                ) : (
                  <FormBtn
                    onClick={this._next}
                    id="applyInfoFormBtn"
                    className="btn"
                    type="button"
                  >
                    <span className="text">Next</span>
                    <span className="icon">
                      <RightLineArrowIcon />
                    </span>
                  </FormBtn>
                )}
              </FormButtonToolbar>
            </div>
          </FormStepFooter>
        </FormStep>
      </form>
    )
  }
}

export default ApplicationInfoForm
